.menuMainContainer {
  background-color: #143051;
  min-height: 100%;
  max-height: 100%;
  overflow: hidden;
  position: fixed;
  top: 0;
  left: 0;
  min-width: 100vw;
  max-width: 100vw;
  /* border-radius: 20px 20px 0 0; */
  /* padding-bottom: -60px; */
  background: rgb(20, 48, 81);
  background: linear-gradient(
    321deg,
    rgba(20, 48, 81, 1) 50%,
    rgba(0, 119, 255, 1) 120%
  );
}

.actionTaskBarContainer {
  background-color: #234f83;
  min-height: 50px;
  max-height: 50px;
  display: flex;
  min-width: 100vw;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 58px;
  display: none;
}

.youHaveRequestsToActionTxt {
  color: white;
  font-size: 0.9em;
  font-weight: 500;
}

.addBtnsBg {
  background: rgba(0, 0, 0, 0.7);
  min-width: 100vw;
  min-height: 100vh;
  position: fixed;
  top: 0;
  right: 0;
  /* z-index: 999; */
}

.menuAddButtonArea {
  /* background-color: #1a406b; */
  max-height: 150px;
  min-height: 160px;
  width: 280px;
  display: flex;
  justify-content: flex-end;
  padding-right: 25px;
  position: fixed;
  bottom: 78px;
  right: 13px;
  color: white;
}

.menuAddSpaceL {
  /* background-color: orange; */
  display: block;
  /* text-align: right; */
  min-width: 200px;
  /* position: fixed; */
  /* right: 25px; */
}

.menuAddSpaceR {
  /* background-color: yellow; */
  display: flex;
  align-items: flex-end;
  margin-bottom: 20px;
  /* position: fixed; */
  /* right: 25px; */
}

.menuAddAbsenceBtn {
  background-color: #00ccff;
  line-height: 36px;
  height: 34px;
  padding-left: 13px;
  padding-right: 13px;
  border-radius: 30px;
  /* padding-top: 1px; */
  font-weight: 500;
  text-align: center;
  max-width: 105px;
  margin-left: 20px;
  font-size: 1.2em;
}

.menuAddOvertimeBtn {
  background-color: #00ccff;
  line-height: 36px;
  height: 34px;
  max-width: 112px;
  padding-left: 13px;
  padding-right: 13px;
  border-radius: 30px;
  /* padding-top: 1px; */
  font-weight: 500;
  text-align: center;
  margin-top: 13px;
  margin-left: 37px;
  font-size: 1.2em;
}

.menuAddShiftBtn {
  background-color: #00ccff;
  line-height: 36px;
  height: 34px;
  max-width: 76px;
  padding-left: 13px;
  padding-right: 13px;
  border-radius: 30px;
  /* padding-top: 1px; */
  font-weight: 500;
  text-align: center;
  margin-top: 13px;
  margin-left: 62px;
  font-size: 1.2em;
}

.menuAddLeaveBtn {
  background-color: #fff;
  line-height: 34px;
  height: 34px;
  color: #00ccff;
  max-width: 78px;
  padding-left: 13px;
  padding-right: 13px;
  border-radius: 30px;
  padding-top: 1px;
  font-weight: 500;
  text-align: center;
  margin-top: 13px;
  margin-left: 98px;
  font-size: 1.2em;
}

.menuAddPlusBtn {
  min-height: 55px;
  max-height: 55px;
  min-width: 55px;
  max-width: 55px;
  background-color: #234f83;
  color: #fff;
  transform: rotate(45deg);
  line-height: 55px;
  font-size: 2.7em;
  border-radius: 100%;
  font-weight: 500;
}

.menuAddPlusBtnInactive {
  min-height: 55px;
  max-height: 55px;
  min-width: 55px;
  max-width: 55px;
  background-color: #fff;
  color: #234f83;
  line-height: 55px;
  font-size: 2.7em;
  border-radius: 100%;
  font-weight: 500;
  position: fixed;
  bottom: 98px;
  right: 38px;
}

.heresYourLeaveTxt {
  color: white;
  text-align: left;
  font-weight: 500;
  margin-top: 25px;
  margin-left: 25px;
  font-size: 1.1em;
}

/*  day user request modal */
.reqModalBackdrop {
  background-color: #143051;
  /* background-color: rgb(21, 31, 45, 0.95); */

  /* background: rgb(21, 31, 45);
  background: linear-gradient(
    0deg,
    rgba(21, 31, 45, 1) 0%,
    rgba(20, 48, 81, 1) 100%
  ); */
  min-height: 100vh;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 100vw;
  position: fixed;
  z-index: 999999999999999;
  top: 0;
  left: 0;
  animation-name: underlayFadeIn;
  animation-duration: 0.1s;
  -webkit-backdrop-filter: blur(3px);
  backdrop-filter: blur(3px);
  background-color: rgb(119 119 119 / 50%);
}

.menuBottomContainer {
  background-color: #234f83;
  min-height: 100px;
  display: flex;
  position: absolute;
  min-width: 100%;
  bottom: 58px;
  min-height: 280px;
  border-radius: 35px 35px 0 0;
}

.numberFloated {
  font-size: 3.5em;
  margin-top: -32px;
  z-index: 0;
  position: fixed;
  color: white;
  font-weight: 500;
  margin-left: 28px;
}

.unitFloatedSpan {
  font-size: 0.3em;
  font-weight: 500;
  margin-left: -10px;
}

.menuBottomDataContainer {
  /* background-color: orange; */
  color: white;
  max-height: 100px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  min-width: 90%;
  max-width: 90%;
  margin: 0 auto;
  margin-top: 44px;
}

.dataContainerUnit {
  padding-left: 10px;
  padding-right: 10px;
}

.dataUnitTitle {
  font-size: 1em;
  font-weight: 500;
  opacity: 0.7;
}

.dataUnitValue {
  margin-top: 5px;
  font-weight: 500;
  font-size: 2em;
  display: flex;
  justify-content: center;
}

.dataUnitValueSpan {
  font-weight: 300;
  font-size: 0.5em;
  margin-left: 2px;
  margin-top: 15px;
}

.dataUnitValueSpanNd {
  font-weight: 300;
  font-size: 0.35em;
  margin-left: 1px;
  margin-top: 5px;
}

.timeInputTrial {
  min-width: 90px;
  /* background-color: orange; */
  max-width: 90px;
  color: #8ea7c5;
  text-align: center;
  /* margin-left: 42px; */
  font-size: 1.2em;
  font-weight: 500;
  padding-bottom: 4px;
}

.menuWhiteOverlayContainer {
  /* background-color: yellow; */
  max-width: 100vw;
  min-width: 100vw;
  min-height: 220px;
  max-height: 240px;
  position: absolute;
  top: 0px;
  opacity: 0.1;
  z-index: -1;
}

.menuWhiteOverlaySquare {
  min-height: 170px;
  max-height: 170px;
  min-width: 100%;
  max-width: 100%;
  background-color: white;
  position: relative;
  z-index: 100;
  /* display: none; */
}

.homeBulge {
  max-width: 100%;
}

.menuTopRow1 {
  /* background-color: green; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* padding-left: 4%; */
  /* padding-right: 4%; */
  margin-top: 20px;
  margin-left: 5%;
}

.editProfileImg {
  margin-top: 13px;
  /* margin-left: 24px; */
  opacity: 0.75;
  min-width: 28px;
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
  cursor: pointer;
}

.menuName {
  color: white;
  font-weight: 500;
  /* font-size: 1.1em; */
  margin-top: -7px;
}

.menuRemaining {
  color: white;
  /* margin-top: 11px; */
  font-size: 1em;
  opacity: 0.75;
  margin-top: 3px;
}

.menuRemainingDays {
  color: white;
  /* margin-top: 11px; */
  font-size: 1em;
  opacity: 0.5;
  margin-top: 3px;
}

.menuRemainingBoldSpan {
  font-weight: 500;
}

.menuShiftTodayTxt {
  color: white;
  font-weight: 500;
  font-size: 16px;
  text-align: left;
  opacity: 0.3;
  display: flex;
  /* line-height: 22px; */
  /* min-height: 18px; */
}

.nextLeaveTxt {
  color: white;
  margin-top: 3px;
  font-weight: 300;
  font-size: 14px;
  text-align: left;
  opacity: 0.3;
}

.menuClockOnDiv {
  /* background-color: white; */
  max-width: 96px;
  min-width: 96px;
  border-radius: 5px;
  display: flex;
  min-height: 35px;
  align-items: center;
  font-weight: 500;
  /* color: #516b8b; */
  font-size: 14px;
  justify-content: center;
  padding-right: 0px;
  padding-left: 0px;
  border: 2px solid #516b8b;
  cursor: pointer;
  color: white;
  background-color: #0bf;
  border: 2px solid #0af;
  /* margin-right: -10px; */
  /* margin: 16px auto; */
}

.menuClockOnDiv:active {
  transform: scale(0.96);
  opacity: 0.9;
}
.menuClockOnPowerImg {
  margin-left: 6px;
  margin-right: 0px;
  margin-top: -1px;
  max-width: 14px;
}

.upcomingNotificationsContainer {
  /* background-color: blue; */
  display: flex;
  justify-content: space-between;
  padding-right: 5%;
  padding-left: 5%;
  font-size: 15px;
  font-weight: 500;
  color: white;
}

.fadedNotifUpcomingTxt {
  opacity: 0.5;
  margin-top: 5px;
}

.upcomingTitle {
  /* text-decoration: underline; */
  display: flex;
  padding-top: 6px;
  animation-name: zoomModalIn !important;
  animation-duration: 0.1s !important;
}

.none {
  display: none;
}

.upcomingUnderling,
.notifUnderling {
  min-height: 3px;
  background-color: #8ea7c5;
  min-width: 100%;
  margin-top: 2px;
  opacity: 0.7;
  border-radius: 10px;
  animation-name: zoomModalIn !important;
  animation-duration: 0.1s !important;
}

.notifCount {
  font-size: 11px;
  background-color: #0077ff;
  border:1px solid #143051;
  border-radius: 5px;
  min-width: 20px;
  max-width: 20px;
  min-height: 20px;
  max-height: 20px;
  margin-top: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 4px;
  margin-bottom: -2px;
}

.notifCountPurple {
  /* background-color: #a67cff; */
  background-color: #ef5353;
  border: 1px solid #ba0000;
}

.menuUpcNotifDivider {
  min-height: 1px;
  background-color: white;
  min-width: 100%;
  max-width: 100%;
  margin: 7px auto;
  opacity: 0.1;
  margin-bottom:0px;
}

.menuProfPicImg {
  max-width: 60px;
  min-width: 60px;
  border-radius: 10px;
  max-height: 70px;
  min-height: 70px;
  object-fit: cover;
  /* margin-top: -5px; */
  border: 3px #8ea7c5 solid;
}

.menuTopRow1SideUnit {
  min-width: 72px;
  /* margin-left: 14px; */
  /* background-color: blue; */
  display: flex;
  justify-content: center;
  margin-right: 20px;
}

.menuTopSectionContainer {
  display: flex;
  /* background-color: orange; */
  justify-content: space-between;
  min-height: 120px;
  align-items: center;
  margin-top: 16px;
}

.menuTopRow2 {
  /* background-color: yellow; */
  min-width: 188px;
  max-height: 180px;
  display: flex;
  flex-direction: column;
  padding-top: 6px;
  justify-content: space-around;
  text-align: left;
  padding-left: 10px;
}

.menuTopDivider {
  background-color: white;
  opacity: 0.4;
  min-height: 1px;
  min-width: 90%;
  max-width: 90%;
  margin: 0 auto;
  margin-bottom: 12px;
}

.topMenuContent {
  /* background-color: blue; */
  min-height: 200px;
  /* max-height: 200px; */
  animation-name: fadeModalIn !important;
  animation-duration: 0.2s !important;
  /* animation-name: zoomModalIn !important; */
  /* animation-duration: 0.2s !important; */
}

.topMenuContentLoad {
  /* background-color: blue; */
  min-height: 200px;
  /* animation-name: fadeModalIn !important;
  animation-duration: 0.1s !important; */
}

.menuLoadingImg {
  max-width: 50px;
  margin-top: 70px;
}

.shiftTodayRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* background-color: blue; */
  padding-left: 7%;
  padding-right: 7%;
  margin-top: -4px;
  min-height: 20px;
}

.menuAllowancesContainer {
  min-height: 400px;
  /* background-color: rgba(255, 255, 255, 0.8); */
  background-color: #dde9f9;
  border-radius: 15px 15px 0 0;
  /* border-top: 2px solid #143051; */
  /* background-color: white; */
  min-height: calc(100vh - 46px);
  max-height: calc(100vh - 186px);
  /* animation-name: heightDown;
  animation-duration: 0.1s; */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  z-index: 9999999;
  position: fixed;
  bottom: 0;
  min-width: 100vw;

  
}

.allowPanelTop {
  /* background-color: yellow; */
  /* background-color: yellow; */
  min-height: 60px;
  padding-top: 20px;
  text-align: left;
  padding-left: 5%;
  padding-right: 5%;
  color: #143051;
  font-weight: 500;
  font-size: 14px;
  line-height:23px;
}

.excessMpdBold {
  font-weight: 500;
  background-color: #2066b6;
  color: white;
  border-radius: 5px;
  padding-left: 5px;
  padding-right: 5px;
  white-space: nowrap
}
.allowPanelMiddle {
  /* background-color: green; */
}

.allowPanelBottom {
  /* background-color: blue; */
  padding-bottom: 20px;
  padding-top: 5px;
  margin-bottom: 8px;
}

.menuAllowancesPullUpIcon {
  max-width: 70px;
  margin-bottom: 14px;
  margin-top: 8px;
  opacity: 0.8;
  cursor: pointer;
}

.nextLeaveRow {
  margin-top: -10px;
  font-weight: 300;
  font-size: 0.9em;
}

.nextShiftAndNextLeaveDiv {
  max-height: 40px;
  margin-top: 4px;
  /* opacity: 0.3; */
  /* padding-bottom: 4px; */
  /* background-color: blue; */
}

.allowPanelMiddle {
  /* background-color: yellow; */
  margin-top: 20px;
}

.excessDataRow {
  /* background-color: orange; */
  min-width: 90%;
  max-width: 90%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 5%;
  min-height: 40px;
  color: #143051;
  border-bottom: 1px solid #516b8b;
}

.excessDataRow:last-child {
  border: none;
}

.menuExcessTitle {
  font-weight: 500;
  font-size: 14px;
  text-align: left;
  color: #214978;
}

.menuExcessTitleHistory {
  font-weight: 500;
  max-width: 70%;
  text-align: left;
  font-size: 0.94em;
  margin-top: 12px;
}
.allowPanelTopAndMiddle {
  border-bottom: 1px solid #b5c4d5;
  max-height: 100%;
  overflow-y: scroll;
  z-index: 99;
  padding-bottom:40px;
  margin-top:-21px;
  padding-top:20px;
}
.excessHistoryRowDataDiv {
  /* background-color: purple; */
  margin-top: 20px;
}
.excessHistoryRow {
  /* background-color: yellow; */
  min-width: 90%;
  max-width: 90%;
  margin-left: 5%;
  text-align: left;
  /* min-height: 120px; */
  border-bottom: 1px solid #516b8b;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 5%;
}

.excessHistoryRow:last-child {
  border: none;
}

.menuExcessValue {
  color: #0af;
  font-weight: 500;
  font-size: 14px;
}

.excessHistoryRowStart {
  color: #143051;
  font-weight: 500;
  margin-top: 14px;
  margin-bottom: 15px;
  font-size: 16px;
}

.excessHistoryRowTable {
}

.excessHistoryRowTableRow {
  display: flex;
  min-width: 100%;
  justify-content: space-between;
  padding-right: 5%;
  align-items: center;
  min-height: 40px;
  font-size: 0.9em;
  color: #2066b6;
  border-top: 1px solid #8ea7c5;
}

.excessHistoryRowTableRowFirst {
  border: none;
}

.excessHistoryRowTableRowTitle {
  max-width: 67%;
  color: #859dbb;
  font-weight: 500;
  line-height: 16px;
  padding-top: 8px;
  padding-bottom: 5px;
}

.excessHistoryDataTitle {
  font-weight: 500;
  text-align: left;
  margin-top: 40px;
  color: #fff;
  max-width: fit-content;
  background-color: #0077cc;
  margin-left: 8%;
  min-height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 5px;
  border: 1px solid #2066b6;
  margin-top: 70px;
  padding-bottom: 1px;
}

.noBorderExcessData {
  border: none;
}

.showProfileStatsBtn {
  background-color: white;
  color: #0077dd;
  background-color: #184479;
  color: #fff;
  font-weight: 500;
  min-width: fit-content;
  max-width: fit-content;
  padding-left: 8px;
  padding-right: 8px;
  min-height: 30px;
  display: flex;
  align-items: center;
  border-radius: 5px;
  border: 1px solid #143051;
  cursor: pointer;
  font-size: 14px;
  height: 35px;
}

.hideProfileStatsBtn {
  background-color: #143051;
  color: #fff;
  font-weight: 500;
  min-width: -moz-fit-content;
  min-width: fit-content;
  /* min-width: 90px; */
  padding-left: 6px;
  padding-right: 6px;
  min-height: 30px;
  display: flex;
  align-items: center;
  border-radius: 5px;
  border: 1px solid #143051;
  cursor: pointer;
  font-size: 12px;
}

.profileStatsWhiteCross {
  margin-right: 5px;
  margin-top: 1px;
}

.menuMgrNotifsBar {
  /* background-color: rgb(255, 255, 255, 0.05); */
  margin-top: 7px;
  min-height: 35px;
  align-items: center;
  justify-content: space-between;
  padding-left: 5%;
  padding-right: 5%;

  display: flex;
  flex-wrap: wrap;
}

.menuUpcomingHolder {
  /* background-color: blue; */
  min-height: calc(100vh - 293px);
  max-height: calc(100vh - 293px);
  min-width: 100vw;
  overflow-y: auto;
  padding-top: 20px;
}

.upcItemParent {
  display: flex;
  align-items: center;
  margin-bottom: 14px;
  margin-left: 4%;
  animation-name: zoomModalIn !important;
  animation-duration: 0.1s !important;
}

.upcItemL {
  background-color: rgba(1, 120, 255, 1);
  min-width: 43px;
  max-width: 43px;
  min-height: 30px;
  border-radius: 5px 0 0 5px;
  z-index: 22;
  display: flex;
  color: white;
  align-items: center;
  justify-content: flex-start;
  font-weight: 500;
  font-size: 12px;
  padding-left: 7px;
  text-align: left;
}

.upcItemLToday {
  font-size: 11px;
  padding-left: 5px;
}

.upcItemLsmall {
  font-size: 10px;
  line-height: 11px;
  padding-left: 5px;
  text-align: center;
  /* padding-right:1px; */
}
.upcItemR {
  min-width: calc(100% - 33px);
  border-radius: 5px;
  max-width: 90%;
  margin-left: -25px;

  /* margin-left:5%; */
  min-height: 70px;
  background: rgb(1, 120, 255);
  background: linear-gradient(
    90deg,
    rgba(1, 120, 255, 1) 8%,
    rgba(37, 73, 114, 1) 100%
  );
  padding-left: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #243a55;
}

.upcItemRUser {
  min-height:90px;
}
.absentUpcItemL {
  background: rgb(176 86 86) 8%;

}

.absentUpcItemR {
  background: rgb(176 86 86) 8%;
  background: linear-gradient(
    90deg,
    rgb(176 86 86) 8%,
    rgb(139 31 31) 100%
  )}
.tilOvertimeUpcItemR {
  background: rgb(0, 204, 255);
  background: linear-gradient(
    90deg,
    rgba(0, 204, 255, 1) 8%,
    rgba(37, 73, 114, 1) 100%
  );
}

.tilOvertimeUpcItemRBlob {
  background-color: rgb(0, 204, 255);
}

.absenceUpcItemR {
  background: rgb(120, 164, 215);
  background: linear-gradient(
    90deg,
    rgba(120, 164, 215, 1) 8%,
    rgba(37, 73, 114, 1) 100%
  );
}

.absenceUpcItemRBlob {
  background-color: rgb(120, 164, 215);
}

.leaveUpcItemR {
  background: rgb(19, 217, 169);
  background: linear-gradient(
    90deg,
    rgba(19, 217, 169, 1) 8%,
    rgba(37, 71, 112, 1) 100%
  );
}

.leaveUpcItemRBlob {
  background-color: rgb(19, 217, 169);
}

.pHolUpcItemR {
  background: #143041;
  border: 2px dotted #214978;
}

.pHolUpcItemRBlob {
  background-color: #143041;
}

.notificationsBox {
  padding-top: 10px;
  max-height: calc(100vh - 290px);
  /* overflow-y: auto; */
  border-top: 1px solid #223c5c;
  animation: notifsFadeIn 0.14s ease-in-out; /* Adjust the duration and timing function as needed */


}


.upcomingScrollBox {
  padding-top: 10px;
  max-height: calc(100vh - 340px);
  overflow-y: auto;
  border-top: 1px solid #223c5c;
  animation: notifsFadeIn 0.075s ease-in-out; /* Adjust the duration and timing function as needed */

}

.notificationsBoxMob {
  max-height: calc(100vh - 28px);}

@keyframes notifsFadeIn {
  from {
    opacity: 0;

  }
  to {
    opacity: 1;
  }
}

.upcItemRightLeft {
  /* background-color: yellow; */
  min-width: 57%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.upcItemRightRight {
  /* background-color: lime; */
  min-width: 33%;
  margin-right: 18px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  text-align: right;
}

.upcItemRightRightTop {
  font-weight: 500;
  font-size: 16px;
  color: #a8d1ff;
  white-space: nowrap;

}

.upcItemRightRightBottom {
  font-size: 12px;
  font-weight: 500;
  color: #b7c3d1;
}

.tilTxtSize {
  font-size: 16px;
  line-height: 16px;
}

.upcomingDatesParent {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-left: 2px;
}

.upcomingDateToken {
  min-width: 46px;
  min-height: 46px;
  border-radius: 10px;
  /* margin-top:1px; */
  /* border: 1px solid #143051 */
}

.upcTokenTop {
  font-weight: 500;
  font-size: 18px;
  text-align: center;
  border: 3px solid #143051;
  border-radius: 10px 10px 0 0;
  min-height: 36px;
  line-height: 22px;
  padding-top: 1px;
  background-color: #f4f8fe;
  color: #143051;
  display: flex;
  justify-content: center;
}

.upcTokenBottom {
  font-weight: 500;
  font-size: 12px;
  color: #516b8b;
  border: 3px solid #516b8b;
  border-radius: 3px;
  margin-top: -10px;
  background-color: white;
  border-radius: 8px 8px 10px 10px;
  padding-bottom: 1px;
  min-height: 22px;
}

.upcTokenDivider {
  color: white;
  margin-left: 3px;
  margin-right: 3px;
  font-size: 20px;
  font-weight: 500;
}

.upcTokenHalf {
  font-weight: 500;
  font-size: 14px;
  margin-left: 1px;
  opacity: 0.6;
}

.upcLeaveDur {
  font-weight: 500;
  color: white;
  line-height: 14px;
  text-align: left;
  font-size: 12px;
  /* padding-right:8px; */
  margin-left: 8px;
}

.notYetStartedUpcoming {
  font-size: 14px;
  margin-left: 10px;
  line-height: 16px;
}

.desktopUpcomingTitle {
  color: white;
  font-weight: 500;
  text-align: left;
  margin-top: 3px;
  margin-left: 13px;
  font-size: 16px;
  margin-bottom: 8px;
  line-height: 26px;
}

.loadUpcomingMoreBtn {
  font-weight: 500;
  font-size: 14px;
  text-align: center;
  margin-bottom: 20px;
  color: white;
  cursor: pointer;
  border: 1px solid #8b9db0;
  max-width: 100px;
  border-radius: 35px;
  min-height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  margin-bottom: 20px;
  padding-left:10px;
  padding-right:10px;

}

.loadUpcomingMoreBtn:hover {
  opacity: 0.8;
}

.upcCentralReq {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.upcUserRow {
  text-align: left;
  color: #ffffff;
  font-weight: 600;
  margin-bottom: 6px;
  font-size: 12px;
}
.upcUserRowReq {
  margin-left:10px
}
.upcShiftInfo {
  /* background-color: blue; */
  max-height: 50px;
  margin-left: 10px;
}

.upcShiftInfoUser {
  max-height:90px
}

.upcShiftInfoTop {
  color: white;
  font-weight: 500;
  text-align: left;
  font-size: 16px;
  max-width: 306px;
}

.upcShiftInfoBottom {
  color: #b8d6f9;
  font-weight: 500;
  text-align: left;
  font-size: 12px;
  display: flex;
  align-items: center;
  margin-top: 2px;
}

.upcTagsSpan {
  display: flex;
  margin-left: 5px;
  border-left: #b8d6f9 1px solid;
  padding-left: 5px;
  line-height: 11px;
  font-size: 10px;
}

.upcShiftTime {
  min-width: fit-content;
}

.pHolSpecDayWidthUpcRight {
  min-width: 50%;
}

.specDayRightLeft {
  /* background-color: yellow; */
  min-width: 00%;
}

.pHolStatus {
  opacity: 0.5;
}

.menuNoProPic {
  max-width: 44px;
  max-height: 44px;
  min-width: 44px;
  min-height: 44px;
}

.menuProPicPlaceholder {
  max-width: 44px;
  max-height: 44px;
  min-width: 44px;
  min-height: 44px;
  border: 2px solid #143051;
  border-radius: 5px;
  /* object-fit: cover; */
}

.menuProPicInits {
  margin-top: -37px;
  opacity: 0.5;
  color: white;
  font-weight: 500;
  /* display: none; */
}
.excessHistoryRowTableRowValue {
  font-weight: 500;
}


.loadingExcessDiv {
  border-bottom: 1px solid #b5c4d5;
  max-height: 100%;
  overflow-y: scroll;
  z-index: 99999999999999;
  min-height: calc(100vh - 186px);
  max-height: calc(100vh - 186px);
}

.excessLoadingMenu {
  min-height:80px;
  max-height:80px;
}

.loadingExcessImg {
  max-width:100px;
  margin-top:33px;
}

.clockOffBtnActive {
  background-color: #1e88e5;
  border: 2px solid #1d71d1;
}

.clockedBrkBtnTxtSize {
min-width:131px;
}

.menuSuff {
  font-size: 8px;
  margin-right: 4px;}



  .x213982 {
    margin-top: -8px;
  }


  .clockRowDs {
    font-weight:500;
    font-size:12px;
    color: #214978;
    margin-top:4px;
    margin-left:10px;
    display: flex;
    align-items: center;
  }

  .clockDeductImg {
    max-width: 20px;
    border: 1px solid #dee7f0;
    border-radius: 3px;
    margin-left: 6px;
    margin-right: 6px;
    cursor: pointer;

}
@media only screen and (min-width: 820px) {
  .clockDeductImg:hover {
    background-color: #dee7f0;
  }

  .menuMainContainer {
    visibility: hidden;
  }
}



.warnRedMinus {
  border-color: #ba0000 !important;
  color: #ba0000 !important;
  font-weight: 600 !important;
}

  .invalidClockNameRow {
    margin-bottom:18px;
  }

.invalidClockOffTime {
  pointer-events: none;
  opacity: 0.3;
}

.x131212 {
  min-width: calc(100% - 15px);
  /* background: blue; */
  display: flex;
  justify-content: center;
  max-width: calc(100% - 15px);
  font-size:16px;
  margin-top:20px;
  margin-bottom:14px;
}

.x0910931 {
  margin-right: 10px;
  margin-left: 10px;
  min-width: 23px;}

  .x3893881 {
    min-width: 90px;
    font-size: 13px;  }

  .clockOffInputTime {
    background-color: #fff;
    width: 77px;
    max-width: 77px;
    line-height: 30px;
    padding-top: 0px;
    height: 35px;
    border: 2px solid #dde9f9;
    border-radius: 5px;
    text-align: center;
    color: #496375;
    font-size: 16px;
    font-weight: 500;
    /* -webkit-appearance: none; */
    /* -moz-appearance: none; */
    /* appearance: none; */
    cursor: text;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    margin-bottom: 3px;
    }

    .x1289347 {
      align-items: center;
      display: flex;
      flex-direction: column;    }

      .mgrMobTopRight {
        min-height: 40px;
        min-width: calc(100vw - 132px);
        /* background-color: blue; */
        display: flex;
        align-items: center;
        justify-content: flex-end;
        position: fixed;
        top: 0;
        right: 0;
      }


      .mgrMobTopRightBtn {
        font-size: 10px;
        font-weight: 500;
        color: white;
        background: #214978;
        border: 1px solid #143051;
        border-radius: 5px;
        height: 27px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-left: 5px;
        padding-right: 4px;
        margin-right: 5px;
      }

      .mgrMobTopRightBtnQty {
        background-color: #a387df;
    border-radius: 3px;
    margin-left: 5px;
    padding: 2px;
    font-size: 9px;
    min-width: 15px;
    padding-left: 4px;
    padding-right: 4px;
      }
      



      .menuMobHeaderSec {
        min-height: 200px;
        margin-bottom: -179px;
        z-index: 999;   
      /* background-color: blue;  */
      }

      .menuMgrRow {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid rgba(255, 255, 255, 0.1);
        max-width: 90%;
        margin-left: 5%;
        min-height: 80px;
        margin-top: 12px;
        padding-bottom: 12px;
      }

      .menuTodayImg {
        min-width: 45px;
        margin-left: 4px;
        max-width: 45px;
      }

      .menuMgrRowRight {
        color:white;
        margin-left:25px;
        text-align: left;
      }

    .menuMgrRowTitle {
      font-weight: 500;
      font-size:18px;
      
    }

    .menuMgrRowLeft {
      display: flex;
      align-items: center;
    }

    .menuMgrRowSub{
      font-weight: 400;
      font-size: 13px;
      margin-top: 3px;
      display: flex;
      align-items: center;
      padding-right: 40px;
    }

    .menuMgrRowSubNoSwaps {
      display: block;
    }

    .menuMgrPopUpImg {
      width:24px;
      opacity: 0.5;
      margin-right: 10px;
    }

    .reverse {
      flex-direction: row-reverse;
    }

    .finalMgrMenuItem {
      margin-bottom:30px
    }

    .menuMgrColour {
      color:#dcd5f7
    }

    .menuUserColour {
      color:#9ddcf6
    }

    .menuShadow {
      min-width: 100%;
      max-width: 100%;
      /* margin-left:5%; */
      position: fixed;
      left:0;
      top:238px;
      min-height:20px;
      /* margin-bottom:-32px; */
      /* margin-top:-10px; */
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.12), rgba(0, 0, 0, 0));
    }

    .menyQtyHighlight {
      /* background-color: #a67cff; */
      font-weight: 500;
      font-size: 16px;
      border-radius: 3px;
      padding-left:5px;
      padding-right:5px;
      margin-right:7px;

      background-color: #ef5353;
      border: 1px solid #ba0000;
    }

    .menyQtyHighlightUser {
      background-color: #0277ff;
      font-weight: 500;
      font-size: 16px;
      border-radius: 3px;
      padding-left: 5px;
      padding-right: 5px;
      border: 1px solid #143051;
      margin-right: 7px;
    }

    .menyQtyHighlightGreen {
      background-color: #82c26a;
    border: 1px solid #808f7b;
    }


    .upcomingNotifContainer {
      /* background-color: yellow; */
    }
   


    .uncTilShift {
      /* background-color: pink; */
      min-width: 96%;
    }

    .paddingTop4 {
      padding-top:4px;
    }

    .excessRefPeriodInfo {
      color: #516b8b;
      font-size: 12px;
      font-weight: 500;
      margin-left: 30px;
      margin-top: 20px;
      padding-right: 25px;
      text-align: left;
      border-left: 2px solid #516b8b;
      padding-left: 10px;
    }

    .NoQtySpaceRightCorr {
      /* padding-right:5px */
    }

    .NoQtySpaceLeftCorr {
      padding-left:5px;
    }

